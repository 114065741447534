import {CLEAR_ERROR, FETCH_ERROR} from './actionTypes'

export default (state = null, action) => {
  if (action.type === FETCH_ERROR) {
    return action.payload
  }

  if (action.type === CLEAR_ERROR) {
    return null
  }

  return state
}
