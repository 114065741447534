import React from 'react'
import {Switch, Route, useRouteMatch} from 'react-router-dom'

import CreateOrder from './Create'

export default function Order() {
  const match = useRouteMatch()

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/create`}>
          <CreateOrder />
        </Route>
        <Route path={`${match.path}/edit/:id`}>xxx</Route>
      </Switch>
    </div>
  )
}
