import React, {useReducer, useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {find, filter, map, reduce} from 'lodash'
import {wrapComponent} from 'react-snackbar-alert'
import DatePicker from 'react-datepicker'
import {fi} from 'date-fns/locale'
import {actionCreators, selectors} from '../../features/inventory'
import 'react-datepicker/dist/react-datepicker.css'
import {getJSON, postJSON} from '../../utils'

const createProductList = (products, onChange) =>
  // eslint-disable-next-line fp/no-mutating-methods
  map(Object.keys(products).sort(), (id, index) => {
    const product = products[id]
    let diff = 'N/A'

    if (`${product.newBottles}`.match(/^-?\d+$/)) {
      const newBottles = parseInt(product.newBottles, 10)

      if (!Number.isNaN(newBottles)) {
        // eslint-disable-next-line fp/no-mutation
        diff = product.newBottles - product.currentBottles
        if (diff > 0) {
          // eslint-disable-next-line fp/no-mutation
          diff = `+${diff}`
        }
      }
    }

    return (
      <li key={id} className={`large ${index % 2 === 0 ? 'odd' : ''}`}>
        <div className="product-id">{id}</div>
        <div className="product-name">{product.name}</div>
        <div className="product-bottles">{product.currentBottles}</div>
        <div className="product-add">{diff}</div>
        <div className="product-bottles-input">
          <input
            type="text"
            size="4"
            name={`${id}-bottles`}
            value={product.newBottles}
            onChange={e => onChange(id, 'newBottles', e.target.value)}
            autoComplete="please-chrome-dont-do-it"
          />
        </div>
        <div className="product-comment">
          <input
            type="text"
            name={`${id}-comment`}
            placeholder="Comment"
            value={product.comment}
            onChange={e => onChange(id, 'comment', e.target.value)}
          />
        </div>
      </li>
    )
  })

const INIT_INVENTORY = 'INIT_INVENTORY'
const INIT_DRAFT = 'INIT_DRAFT'
const UPDATE_VALUE = 'UPDATE_VALUE'

const reducer = (state, action) => {
  if (action.type === INIT_INVENTORY) {
    return Object.keys(action.payload).reduce(
      (out, id) => ({
        ...out,
        [id]: {
          name: action.payload[id].name,
          newBottles: parseInt(action.payload[id].bottles, 10),
          currentBottles: parseInt(action.payload[id].bottles, 10),
          comment: '',
        },
      }),
      {}
    )
  }

  if (action.type === INIT_DRAFT) {
    return action.payload.reduce(
      (out, prod) => ({
        ...out,
        [prod.product_id]: {
          name: prod.name,
          newBottles: parseInt(prod.newBottles, 10),
          currentBottles: parseInt(prod.oldBottles, 10),
          comment: prod.comment,
        },
      }),
      {}
    )
  }

  if (action.type === UPDATE_VALUE) {
    const {id, name, value} = action.payload
    return {
      ...state,
      [id]: {
        ...state[id],
        [name]: value,
      },
    }
  }

  return state
}

const validateProductAmounts = products => {
  const invalidRow = find(products, (v, k) =>
    Number.isNaN(parseInt(v.newBottles, 10))
  )

  return invalidRow
}

export default wrapComponent(function InventoryReport({createSnackbar}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const {inventoryId, warehouseId} = useParams()
  const inventory = useSelector(selectors.getInventory)
  const [state, localstateDispatch] = useReducer(reducer, {})
  // const [inventoryInitialized, setInventoryInitialized] = useState(false)
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    if (inventoryId) {
      // continue draft
      const fetchInventoryDetails = async () => {
        const draft = await getJSON(`/inventory/report/details/${inventoryId}`)
        setDate(new Date(draft.created))
        localstateDispatch({type: INIT_DRAFT, payload: draft.products})
      }

      fetchInventoryDetails()
    } else {
      // new report
      const initialInventory = reduce(
        filter(inventory, p => p.bottles > 0),
        (out, val, key) =>
          val.warehouse === warehouseId ? {...out, [key]: val} : out,
        {}
      )

      localstateDispatch({type: INIT_INVENTORY, payload: initialInventory})
    }
  }, [inventoryId, inventory])

  const onChange = (id, name, value) => {
    localstateDispatch({type: UPDATE_VALUE, payload: {id, name, value}})
  }

  const onChangeDate = newDate => {
    setDate(newDate)
  }

  const onSaveDraft = async () => {
    const saveBaseURL = '/inventory/report/draft'
    const saveURL = inventoryId ? `${saveBaseURL}/${inventoryId}` : saveBaseURL

    const status = await postJSON(saveURL, {
      date,
      warehouseId,
      inventory: state,
    })

    createSnackbar(
      status.ok
        ? {
            message: 'Draft saved',
            progressBar: true,
            dismissable: true,
            theme: 'success',
            timeOut: 1500,
          }
        : {
            message: status.err.toString(),
            dismissable: true,
            sticky: true,
            theme: 'error',
          }
    )

    if (status.ok) {
      // eslint-disable-next-line fp/no-mutating-methods
      history.push('/logistics/inventory')
    }
  }

  const onSave = async () => {
    const invalidProduct = validateProductAmounts(state)

    if (invalidProduct) {
      createSnackbar({
        message: `${invalidProduct.name}: Invalid amount '${invalidProduct.newBottles}'`,
        theme: 'error',
        progressBar: true,
        dismissable: true,
        timeOut: 1500,
      })

      return
    }

    const saveBaseURL = '/inventory/report/create'
    const saveURL = inventoryId ? `${saveBaseURL}/${inventoryId}` : saveBaseURL

    const status = await postJSON(saveURL, {
      date,
      inventory: state,
    })

    createSnackbar(
      status.ok
        ? {
            message: 'Inventory updated',
            progressBar: true,
            dismissable: true,
            theme: 'success',
            timeOut: 1500,
          }
        : {
            message: status.err.toString(),
            dismissable: true,
            sticky: true,
            theme: 'error',
          }
    )

    if (status.ok) {
      dispatch(actionCreators.reloadInventory)
      // eslint-disable-next-line fp/no-mutating-methods
      history.push('/stock/inventory')
    }
  }

  return (
    <>
      <h3>Date</h3>
      <div>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          locale={fi}
          selected={date}
          onChange={onChangeDate}
        />
      </div>
      <h3>Products</h3>
      <ul className="product-list noborder">
        <li className="header">
          <div className="product-id">Product id</div>
          <div className="product-name">Name</div>
          <div className="product-bottles">Current</div>
          <div className="product-add">Change</div>
          <div className="product-bottles-input">New</div>
        </li>
        {createProductList(state, onChange)}
      </ul>

      <button className="button-wide" type="submit" onClick={onSaveDraft}>
        Save draft
      </button>

      <button className="button-wide" type="submit" onClick={onSave}>
        Save
      </button>
    </>
  )
})
