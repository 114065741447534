import React from 'react'
import PropTypes from 'prop-types'

const PrintOrder = ({order, products}) => {
  const d = new Date(order.pickupDate)
  const date = `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`

  return (
    <>
      <table width="700">
        <thead>
          <tr>
            <th width="50%">Inta Imports</th>
            <th width="50%">KERÄILYLISTA #{order.id}</th>
          </tr>
          <tr>
            <th colSpan="2">Asiakas</th>
          </tr>
          {[
            [order.name, `Päiväys: ${date}`],
            [order.address, `Viite: ${order.senderReference}`],
            [
              `${order.zip} ${order.city}`,
              `Viitteenne: ${order.receiverReference}`,
            ],
            ['', `Lisätiedot: ${order.details}`],
            [order.addressExtra, ''],
          ].map((pair, i) => (
            // eslint-disable-next-line
            <tr key={i}>
              <td>{pair[0]}</td>
              <td>{pair[1]}</td>
            </tr>
          ))}
        </thead>
      </table>
      <table width="700">
        <tbody>
          <tr>
            <th>Koodi</th>
            <th>Tuote</th>
            <th>Paikka</th>
            <th align="right">Krt</th>
            <th align="right">Määrä</th>
          </tr>
          {products.map(p => (
            <tr key={p.product_id}>
              <td>{p.product_id}</td>
              <td>{p.name}</td>
              <td>
                {p.storageArea} {p.storageShelf}
              </td>
              <td align="right">{p.retails}</td>
              <td align="right">{p.retails * p.retailSize}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

PrintOrder.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    pickupDate: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    senderReference: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    receiverReference: PropTypes.string,
    details: PropTypes.string,
    addressExtra: PropTypes.string,
  }).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PrintOrder
