import React from 'react'
import {useSelector} from 'react-redux'
import {map, filter, reduce} from 'lodash'
import {selectors} from '../../features/inventory'

export default function StockStatus() {
  const numberFormat = new Intl.NumberFormat('fi-FI')
  const currencyFormat = new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
  })

  const products = useSelector(selectors.getInventory)
  const total = reduce(
    products,
    (out, product) => ({
      in: out.in + product.priceIn * product.bottles,
      out: out.out + product.priceOut * product.bottles,
      bottles: out.bottles + product.bottles,
    }),
    {bottles: 0, in: 0, out: 0}
  )

  return (
    <ul className="stock-list">
      <li key="header" className="header">
        <div className="product-id">Product</div>
        <div className="product-bottles">Bottles</div>
        <div className="product-price">Price in</div>
        <div className="product-price">Price out</div>
      </li>
      <li key="total" className="total">
        <div className="product-id">Total</div>
        <div className="product-bottles">
          {numberFormat.format(total.bottles)}
        </div>
        <div className="product-price">{currencyFormat.format(total.in)}</div>
        <div className="product-price">{currencyFormat.format(total.out)}</div>
      </li>

      {map(
        products,
        // filter(products, p => p.bottles > 0),
        (p, id) =>
          p.bottles > 0 ? (
            <li key={id}>
              <div className="product-id">{id}</div>
              <div className="product-bottles">
                {numberFormat.format(p.bottles)}
              </div>
              <div className="product-price">
                {currencyFormat.format(p.bottles * p.priceIn)}
              </div>
              <div className="product-price">
                {currencyFormat.format(p.bottles * p.priceOut)}
              </div>
            </li>
          ) : null
      )}
    </ul>
  )
}
