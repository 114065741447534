import React from 'react'
import {Switch, Route, useRouteMatch, Link} from 'react-router-dom'

import Arrival from './Arrival'
import OrderList from './Order'
import ArrivalHistory from './ArrivalHistory'
import InventoryDetails from './InventoryDetails'

const Logistics = () => {
  const match = useRouteMatch()

  return (
    <>
      <Switch>
        <Route path={`${match.path}/arrival`}>
          <Arrival />
        </Route>
        <Route path={`${match.path}/order/open`}>
          <OrderList mode="open" />
        </Route>
        <Route path={`${match.path}/order/history`}>
          <OrderList mode="delivered" />
        </Route>
        <Route path={`${match.path}/arrivals`}>
          <ArrivalHistory />
        </Route>
        <Route path={`${match.path}/inventory/:inventoryId`}>
          <InventoryDetails />
        </Route>

        <Route path={match.path}>
          <h2>Logistics</h2>

          <p className="button">
            <Link to={`${match.path}/order/open`}>Open orders</Link>
          </p>

          <p className="button">
            <Link to={`${match.path}/order/history`}>Order history</Link>
          </p>

          <p className="button">
            <Link to={`${match.path}/arrival`}>New arrival</Link>
          </p>

          <p className="button">
            <Link to={`${match.path}/arrivals`}>Past arrivals</Link>
          </p>
        </Route>
      </Switch>
    </>
  )
}

export default Logistics
