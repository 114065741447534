import React from 'react'
import {BrowserRouter, Switch, Route, NavLink} from 'react-router-dom'
import {SnackbarProvider} from 'react-snackbar-alert'
import {useDispatch, useSelector} from 'react-redux'

import {Icon} from 'react-icons-kit'
import {wineglass} from 'react-icons-kit/ionicons/wineglass'
import {plane} from 'react-icons-kit/ionicons/plane'
import {iosList} from 'react-icons-kit/ionicons/iosList'
import {iosGear} from 'react-icons-kit/ionicons/iosGear'

import Admin from './components/Admin'
import Order from './components/Order'
import Products from './components/Products'
import Login from './components/Login'
import Error from './components/Error'
import Logistics from './components/Logistics'
import Stock from './components/Stock'

import {selectors, actionTypes as errorActions} from './features/error'

const Warehouse = () => {
  const dispatch = useDispatch()
  const error = useSelector(selectors.getError)

  const loggedIn = !error

  if (document.location.pathname === '/login') {
    dispatch({type: errorActions.CLEAR_ERROR})
  }

  return (
    <BrowserRouter>
      <SnackbarProvider position="bottom">
        <div className="app">
          <div id="logo">
            <img src="/inta.jpg" alt="Inta" title="Inta" />
          </div>

          {!loggedIn && <Error />}

          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            {loggedIn && (
              <>
                <Route path="/inventory">
                  <Products />
                </Route>
                <Route path="/order">
                  <Order />
                </Route>
                <Route path="/stock">
                  <Stock />
                </Route>
                <Route path="/logistics">
                  <Logistics />
                </Route>
                <Route path="/admin">
                  <Admin />
                </Route>
              </>
            )}
          </Switch>

          {loggedIn && (
            <ul id="navi">
              <li>
                <NavLink to="/inventory">
                  <Icon size={38} icon={wineglass} />
                  <p>Products</p>
                </NavLink>
              </li>

              <li>
                <NavLink to="/order/create">
                  <Icon size={38} icon={plane} />
                  <p>Shipment</p>
                </NavLink>
              </li>

              <li>
                <NavLink to="/logistics">
                  <Icon size={38} icon={iosList} />
                  <p>Logistics</p>
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin">
                  <Icon size={38} icon={iosGear} />
                  <p>Admin</p>
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </SnackbarProvider>
    </BrowserRouter>
  )
}

export default Warehouse
