import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import store from './store'
import Warehouse from './Warehouse'
import './index.scss'
import * as serviceWorker from './serviceWorker'

if (document.location.pathname === '/') {
  // eslint-disable-next-line fp/no-mutation
  document.location.href = '/inventory'
}

ReactDOM.render(
  <Provider store={store}>
    <Warehouse />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
