import {getJSON} from 'utils'
import {FETCH_ERROR} from '../error/actionTypes'
import {RELOAD_INVENTORY} from './actionTypes'

export const reloadInventory = async dispatch => {
  let inventory = null

  try {
    // eslint-disable-next-line fp/no-mutation
    inventory = await getJSON('/inventory/list')
  } catch (err) {
    dispatch({type: FETCH_ERROR, payload: err.message})
    return
  }

  dispatch({type: RELOAD_INVENTORY, payload: inventory})
}
