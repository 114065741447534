import React, {useEffect, useState} from 'react'
import {groupBy} from 'lodash'
import printPDF from 'print-js'
import {wrapComponent} from 'react-snackbar-alert'
import {getJSON, postJSON} from 'utils'
import PrintOrder from './Print'
import OrderList from './List'
import printStyle from './print-style'

export default wrapComponent(function OrderHistory({createSnackbar, mode}) {
  const [printing, setPrinting] = useState(false)
  const [printingOrder, setPrintingOrder] = useState(null)
  const [orders, setOrders] = useState([])

  const updateOrderStatus = async (orderId, status) => {
    const res = await postJSON(`/order/status/${orderId}`, {status})
    if (res.err) {
      createSnackbar({
        message: res.err,
        theme: 'error',
        sticky: true,
        dismissable: true,
      })
      return
    }

    const updatedOrder = orders.find(order => order.id === orderId)

    createSnackbar({
      message: `Order #${updatedOrder.id} ${updatedOrder.name} status changed from ${updatedOrder.status} to ${status}`,
      progressBar: true,
      theme: 'success',
      timeOut: 1500,
    })

    setOrders(
      orders.map(order => (order.id === orderId ? {...order, status} : order))
    )
  }

  const fetchOrders = async () => {
    const res = await getJSON('/order/list')
    setOrders(res)
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  useEffect(() => {
    if (!printing) {
      return
    }

    printPDF({
      printable: 'print',
      type: 'html',
      documentTitle: `Keräilylista`,
      style: printStyle,
      onPrintDialogClose: () => {
        setPrinting(false)
        setPrintingOrder(null)
      },
    })
  }, [printing])

  const printInvoice = async orderId => {
    const order = await getJSON(`/order/history/${orderId}`)
    setPrintingOrder(order)
    setPrinting(orderId)
  }

  const togglePackedStatus = async orderId => {
    const o = orders.find(order => order.id === orderId)
    let newStatus = o.status

    if (o.status === 'CREATED') {
      newStatus = 'PACKED'
    } else if (window.confirm(`Revert status to open?`)) {
      newStatus = 'CREATED'
    }

    if (newStatus !== o.status) {
      updateOrderStatus(orderId, newStatus)
    }
  }

  const toggleDeliveredStatus = async orderId => {
    const o = orders.find(order => order.id === orderId)
    let newStatus = o.status

    if (o.status === 'CREATED' || o.status === 'PACKED') {
      newStatus = 'DELIVERED'
    } else if (window.confirm(`Revert status to waiting for delivery?`)) {
      newStatus = 'PACKED'
    }

    if (newStatus !== o.status) {
      updateOrderStatus(orderId, newStatus)
    }
  }

  const groupedOrders = groupBy(orders, 'status')

  let orderList = null
  if (mode === 'open') {
    orderList = (
      <>
        <h2 className="section">Open</h2>
        <div className="history">
          {groupedOrders.CREATED && groupedOrders.CREATED.length > 0 ? (
            <OrderList
              orders={groupedOrders.CREATED}
              onClickPacked={togglePackedStatus}
              onClickDelivered={toggleDeliveredStatus}
              onClickPrint={printInvoice}
              status="CREATED"
            />
          ) : (
            <div className="orderItem">No open orders</div>
          )}
        </div>
        <h2 className="section">Waiting for delivery</h2>
        <div className="history">
          {groupedOrders.PACKED && groupedOrders.PACKED.length > 0 ? (
            <OrderList
              orders={groupedOrders.PACKED}
              onClickPacked={togglePackedStatus}
              onClickDelivered={toggleDeliveredStatus}
              onClickPrint={printInvoice}
              status="PACKED"
            />
          ) : (
            <div className="orderItem">No orders waiting for delivery</div>
          )}
        </div>
      </>
    )
  } else {
    orderList = (
      <>
        <h2 className="section">Order history</h2>
        <div className="history">
          {groupedOrders.DELIVERED && groupedOrders.DELIVERED.length > 0 ? (
            <OrderList
              orders={groupedOrders.DELIVERED}
              onClickPacked={togglePackedStatus}
              onClickDelivered={toggleDeliveredStatus}
              onClickPrint={printInvoice}
              status="DELIVERED"
            />
          ) : (
            <div className="orderItem">No shipped orders</div>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {orderList}
      <div id="print">
        {printing && (
          <PrintOrder
            order={printingOrder.order}
            products={printingOrder.products}
          />
        )}
      </div>
    </>
  )
})
