import React from 'react'
import {useSelector} from 'react-redux'
import {selectors} from '../../features/inventory'
import ProductList from '../ProductList'

const AllProducts = () => {
  const products = useSelector(selectors.getInventory)

  return <ProductList products={products} showAll />
}

export default AllProducts
