import React, {useState} from 'react'

// https:docs.google.com/spreadsheets/d/18DRszk2E3oSIDlhCx5zZA_uBi1kJT7Do/edit#gid=2128352194

const parseValue = v => {
  if (v === '') {
    return 0
  }

  return parseFloat(`${v}`.replace(',', '.'))
}

export default function Calculator() {
  const [priceIn, setPriceIn] = useState('')
  const [shipping, setShipping] = useState('')
  const [tax, setTax] = useState('')
  const [margin, setMargin] = useState(1.0)

  const update = updater => e => updater(e.target.value)

  const total = parseValue(priceIn) + parseValue(shipping) + parseValue(tax)
  const price = total * parseValue(margin)
  const profit = price > 0 && total > 0 ? price - total : 0
  const profitperc = profit > 0 ? ((price - total) / price) * 100 : 0

  return (
    <form className="calculator">
      <div className="wrap">
        <div className="col">
          <label htmlFor="pricein">
            Price in
            <input
              id="pricein"
              type="text"
              inputMode="decimal"
              size="6"
              onChange={update(setPriceIn)}
              value={priceIn}
            />
          </label>
        </div>

        <div className="col">
          <label htmlFor="shipping">
            Shipping
            <input
              id="shipping"
              type="text"
              inputMode="decimal"
              size="6"
              onChange={update(setShipping)}
              value={shipping}
            />
          </label>
        </div>

        <div className="col">
          <label htmlFor="tax">
            Tax
            <input
              id="tax"
              type="text"
              inputMode="decimal"
              size="6"
              onChange={update(setTax)}
              value={tax}
            />
          </label>
        </div>
      </div>
      <p>
        Total <em>{total.toFixed(2)}</em>
      </p>

      <p>
        <label htmlFor="margin">
          Margin
          <input
            id="Margin"
            type="text"
            inputMode="decimal"
            size="6"
            onChange={update(setMargin)}
            value={margin}
          />
        </label>
      </p>

      <div className="wrap">
        <div className="col">
          Price <em>{price.toFixed(2)}</em>
        </div>
        <div className="col">
          VAT 24% <em>{(price * 0.24).toFixed(2)}</em>
        </div>
        <div className="col">
          Total <em>{(price * 1.24).toFixed(2)}</em>
        </div>
      </div>
      <p>
        Profit margin <em>{profit.toFixed(2)}</em> (
        <em>{Math.round(profitperc)}%</em>)
      </p>
    </form>
  )
}
