import React from 'react'
import {Link} from 'react-router-dom'
import {Icon} from 'react-icons-kit'
import {iosPaperOutline} from 'react-icons-kit/ionicons/iosPaperOutline'
import {iosPrinterOutline} from 'react-icons-kit/ionicons/iosPrinterOutline'
import {iosCartOutline} from 'react-icons-kit/ionicons/iosCartOutline'
import {plane} from 'react-icons-kit/ionicons/plane'

import {getBaseURL} from 'utils'

const OrderList = ({
  orders,
  status,
  onClickPacked,
  onClickDelivered,
  onClickPrint,
}) => {
  const packedIconClass = status !== 'CREATED' ? 'inactive' : ''
  const deliveredIconClass = status === 'DELIVERED' ? 'inactive' : ''

  return orders
    .filter(order => order.status === status)
    .map(order => {
      const d = new Date(order.pickupDate)

      const products = order.products.map(p => (
        <li key={p.id}>
          <div className="id">
            <Link to={`/inventory/${p.id}`}>{p.id}</Link>
          </div>
          <div className="name">{p.name}</div>
          <div className="count">{p.retails}</div>
          <div className="count">{p.retails * p.retailSize}</div>
        </li>
      ))

      return (
        <div key={order.id} className="orderItem">
          <div className="header">
            <div>
              {order.name}
              <br />
              <span style={{fontWeight: 400}}>
                {d.getDate()}.{d.getMonth() + 1}.{d.getFullYear()} #{order.id}
              </span>
            </div>
            <div className="actions">
              {/* XxxXXXX */}
              {false && status === 'CREATED' && <div>E</div>}
              <div className={packedIconClass}>
                <Icon
                  size={28}
                  icon={iosCartOutline}
                  onClick={() => onClickPacked(order.id)}
                />
              </div>
              <div className={deliveredIconClass}>
                <Icon
                  size={28}
                  icon={plane}
                  onClick={() => onClickDelivered(order.id)}
                />
              </div>
            </div>
          </div>
          <div className="detailWrap">
            <div className="left">
              <div className="details">
                {order.address}, {order.zip} {order.city}
              </div>
              <div className="details">{order.addressExtra}</div>
            </div>

            <div className="icon">
              <Icon
                size={30}
                icon={iosPrinterOutline}
                onClick={() => onClickPrint(order.id)}
              />
            </div>
            <div>
              <a href={`${getBaseURL()}/order/history/${order.id}.pdf`}>
                <Icon size={30} icon={iosPaperOutline} />
              </a>
            </div>
          </div>
          <ul>
            <li className="hdr" key="hdr">
              <div className="id">Product id</div>
              <div className="name">Name</div>
              <div className="count">Rtls</div>
              <div className="count">Btls</div>
            </li>
            {products}
          </ul>
        </div>
      )
    })
}

export default OrderList
