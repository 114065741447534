import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Icon} from 'react-icons-kit'
import {iosPaperOutline} from 'react-icons-kit/ionicons/iosPaperOutline'

import {getJSON, getBaseURL} from '../../../utils'

const createProductList = (products, onChange) =>
  products.map(
    ({product_id: productId, name, newBottles, oldBottles, comment}, index) => {
      let diff = newBottles - oldBottles
      if (diff > 0) {
        // eslint-disable-next-line fp/no-mutation
        diff = `+${diff}`
      }

      return (
        <li key={index} className={`large ${index % 2 === 0 ? 'odd' : ''}`}>
          <div className="product-id">{productId}</div>
          <div className="product-name">{name}</div>
          <div className="product-bottles">{oldBottles}</div>
          <div className="product-add">{diff}</div>
          <div className="product-bottles">{newBottles}</div>
          <div className="product-comment">{comment}</div>
        </li>
      )
    }
  )

export default function StockReport() {
  const {inventoryId} = useParams()
  const [report, setReport] = useState(null)

  useEffect(() => {
    const fetchReport = async () => {
      const res = await getJSON(`/inventory/report/details/${inventoryId}`)
      setReport(res)
    }

    fetchReport()
  }, [])

  if (!report) {
    return null
  }

  const date = new Date(report.created)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return (
    <div>
      <h3>
        Inventory {year}-{month}-{day}
      </h3>
      <div className="link">
        <a href={`${getBaseURL()}/inventory-report/${inventoryId}.xls`}>
          inventory-{year}-{month}-{day}.xls{' '}
          <Icon size={30} icon={iosPaperOutline} />
        </a>
      </div>

      <ul className="product-list noborder">
        <li className="header">
          <div className="product-id">Product id</div>
          <div className="product-name">Name</div>
          <div className="product-bottles">Old</div>
          <div className="product-add">Change</div>
          <div className="product-bottles-input">New</div>
        </li>
        {createProductList(report.products)}
      </ul>
    </div>
  )
}
