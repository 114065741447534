import React from 'react'
import PropTypes from 'prop-types'

const CheckBoxRow = ({title, name, checked, onChange}) => {
  const onToggle = e => {
    onChange(!checked)
  }

  return (
    <div className="row">
      <div className="hdr">{title}</div>
      <div className="val">
        <input
          id="cb-1"
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onToggle}
        />
      </div>
    </div>
  )
}

CheckBoxRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CheckBoxRow
