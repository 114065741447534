import React from 'react'
import {map} from 'lodash'
import Button from 'components/Button'

class ProductList extends React.Component {
  static createProductList(
    products,
    inventory,
    onAddProduct,
    onSetProduct,
    onRemoveProduct
  ) {
    if (!Object.keys(products).length) {
      return null
    }

    return map(products, (rtls, id) => {
      const product = inventory[id]
      const rtlsAvailable = parseInt(product.bottles / product.retailSize, 10)

      return (
        <li key={id}>
          <div className="product-id">{id}</div>
          <div className="product-name">{product.name}</div>
          <div className="product-warehouse">
            {product.warehouse === 'inta' ? 'I' : 'B'}
          </div>
          {/* <div className="product-retailSize">{rtls}</div> */}
          <div className="product-bottles">{rtls * product.retailSize}</div>
          <div className="product-add">
            <div
              onClick={() => onSetProduct(id, rtls * 10)}
              role="button"
              tabIndex="0"
              onKeyPress={() => true}
              style={{
                textAlign: 'center',
                border: '1px solid #555',
                marginLeft: '0.3rem',
                padding: '0.1rem 0',
                borderRadius: '8px',
              }}>
              x10
            </div>
          </div>
          <div className="product-add">
            <Button mode="remove" onClick={() => onRemoveProduct(id)} />
          </div>
          <div className="product-add">
            <Button
              isActive={rtls < rtlsAvailable}
              onClick={() => onAddProduct(id)}
            />
          </div>
        </li>
      )
    })
  }

  constructor(props) {
    super(props)
    this.wrapRef = React.createRef()
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
    // if (prevProps.list.length < this.props.list.length) {
    //   const list = this.listRef.current
    //   return list.scrollHeight - list.scrollTop
    // }

    return this.wrapRef.current.scrollHeight
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevHeight = snapshot
    const currHeight = this.wrapRef.current.scrollHeight

    if (currHeight !== prevHeight) {
      window.scrollBy(0, currHeight - prevHeight)
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const {products, inventory, onAddProduct, onSetProduct, onRemoveProduct} =
      this.props

    const productList = ProductList.createProductList(
      products,
      inventory,
      onAddProduct,
      onSetProduct,
      onRemoveProduct
    )

    return (
      <ul className="product-list noborder" ref={this.wrapRef}>
        <li className="header">
          <div className="product-id">Product id</div>
          <div className="product-name">Name</div>
          <div className="product-warehouse">wh</div>
          {/* <div className="product-retailSize">rtls</div> */}
          <div className="product-bottles">btls</div>
          <div className="product-add">&nbsp;</div>
          <div className="product-add">&nbsp;</div>
          <div className="product-add">&nbsp;</div>
        </li>
        {productList}
      </ul>
    )
  }
}

export default ProductList
