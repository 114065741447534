import React from 'react'
import PropTypes from 'prop-types'

const ButtonRow = ({title, name, value, values, onChange}) => {
  const onSelect = e => {
    onChange(e.target.value)
  }

  return (
    <div className="row">
      <div className="hdr">{title}</div>
      <div className="val">
        <label htmlFor="opt-1">
          <input
            id="opt-1"
            type="radio"
            name={name}
            value="pickup"
            checked={value === 'pickup'}
            onChange={onSelect}
          />
          Pickup
        </label>
        <label htmlFor="opt-2">
          <input
            id="opt-2"
            type="radio"
            name={name}
            value="delivery"
            checked={value === 'delivery'}
            onChange={onSelect}
          />
          Delivery
        </label>
      </div>
    </div>
  )
}

ButtonRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ButtonRow
