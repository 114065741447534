import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {Icon} from 'react-icons-kit'
import {iosPlusOutline} from 'react-icons-kit/ionicons/iosPlusOutline'
import {iosMinusOutline} from 'react-icons-kit/ionicons/iosMinusOutline'
import {iosPlus} from 'react-icons-kit/ionicons/iosPlus'
import {iosMinus} from 'react-icons-kit/ionicons/iosMinus'

const Button = ({isActive, mode, onClick}) => {
  const [pressed, setPressed] = useState(false)

  const iconUnpressed = mode === 'add' ? iosPlusOutline : iosMinusOutline
  const iconPressed = mode === 'add' ? iosPlus : iosMinus
  const color = isActive ? '#000' : '#ccc'

  return (
    <div
      tabIndex="0"
      role="button"
      style={{color}}
      onKeyPress={() => true}
      onClick={() => (isActive ? onClick() : true)}
      onPointerDown={() => setPressed(true)}
      onPointerUp={() => setPressed(false)}>
      <Icon size={28} icon={pressed ? iconPressed : iconUnpressed} />
    </div>
  )
}

Button.propTypes = {
  mode: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

Button.defaultProps = {
  isActive: true,
  mode: 'add',
}

export default Button
