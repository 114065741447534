import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {wrapComponent} from 'react-snackbar-alert'

import {postJSON} from 'utils'
import {actionCreators} from '../../features/inventory'
import ProductEditor from '../ProductEditor'

const newProduct = {
  id: '',
  name: '',
  producer: '',
  ean: '',
  alc: 12.5,
  bottleSize: 0.75,
  bottles: 0,
  retailSize: 6,
  // tuoteryhmä (viini, viina, olut, välituote)
  category: 'wine',
  // hyllypaikka
  warehouse: 'inta',
  storageArea: '',
  storageShelf: '',
  // hinta
  priceIn: 0,
  priceOut: 0,
  taxFree: false,
  taxExempt: false,
  eanRequired: true,
  alkoApproved: false,
}

const categories = {
  wine: 'viini',
  wine15plus: 'viini yli 15%',
  beer: 'olut',
  intermediate: 'välituote',
  liquor: 'viina',
  lowalc: 'low alc.',
  softdrink: 'virvoitusjuoma',
  nonalcoholic: 'alkoholiton',
}

const CreateProduct = wrapComponent(({createSnackbar}) => {
  const [product, setProduct] = useState(newProduct)
  const dispatch = useDispatch()

  const saveProduct = async () => {
    if (!product.id) {
      createSnackbar({
        message: 'Product id missing',
        progressBar: true,
        theme: 'error',
        timeout: 1500,
      })

      return
    }
    const status = await postJSON(`/product/${product.id}/create`, product)

    if (!status.err) {
      dispatch(actionCreators.reloadInventory)
      setProduct(newProduct)
    }

    createSnackbar(
      status.err
        ? {
            message: status.err,
            theme: 'error',
            sticky: true,
            dismissable: true,
          }
        : {
            message: 'Product created',
            progressBar: true,
            theme: 'success',
            timeOut: 1500,
          }
    )
  }

  const updateProduct = (name, value) => {
    setProduct({
      ...product,
      [name]: value,
    })
  }

  return (
    <div>
      <h2>Add new product</h2>
      <ProductEditor
        editId
        product={product}
        categories={categories}
        onUpdate={updateProduct}
        onSave={saveProduct}
      />
    </div>
  )
})

export default CreateProduct
