import React from 'react'
import PropTypes from 'prop-types'
import {fi} from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {getDate} from '../../utils'

const today = getDate(new Date())

const DateRow = ({title, date, onChange, allowGone}) => (
  <div className="row">
    <div className="hdr">{title}</div>
    <div className="val">
      <DatePicker
        dateFormat="yyyy-MM-dd"
        locale={fi}
        selected={date}
        onChange={onChange}
        filterDate={d => (allowGone ? true : getDate(d) >= today)}
      />
    </div>
  </div>
)

DateRow.defaultProps = {
  allowGone: false,
}

DateRow.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  allowGone: PropTypes.bool,
}

export default DateRow
