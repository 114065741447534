import React, {useCallback, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Autosuggest from 'react-autosuggest'
import {wrapComponent} from 'react-snackbar-alert'
import {filter, has, map} from 'lodash'

import TextRow from 'components/TextRow'
import ButtonRow from 'components/ButtonRow'
import DateRow from 'components/DateRow'
import Button from 'components/Button'
import CheckBoxRow from 'components/CheckBoxRow'

import {
  actionCreators,
  selectors as inventorySelectors,
} from 'features/inventory'
import {actionTypes, selectors as orderSelectors} from 'features/order'

import {postJSON, getJSON, numRetailsAvailable} from 'utils'
import ProductList from '../ProductList'

export default wrapComponent(function Order({createSnackbar}) {
  const inventory = useSelector(inventorySelectors.getInventory)
  const order = useSelector(orderSelectors.getOrder)
  const [filterText, setFilterText] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const dispatch = useDispatch()

  const sendOrder = async () => {
    console.log(order.taxfree, order.taxfreeWarehouseId)

    if (order.taxfree && order.taxfreeWarehouseId === '') {
      createSnackbar({
        message: 'Receiver warehouse id missing',
        theme: 'error',
        sticky: true,
        dismissable: true,
      })
    }

    const status = await postJSON('/order/create', order)

    if (!status.err) {
      dispatch({type: actionTypes.RESET_ORDER})
      dispatch(actionCreators.reloadInventory)
    }

    createSnackbar(
      status.err
        ? {
            message: status.err,
            theme: 'error',
            sticky: true,
            dismissable: true,
          }
        : {
            message: `Order ${status.orderId} created`,
            progressBar: true,
            theme: 'success',
            timeOut: 1500,
          }
    )
  }

  const updateValue = useCallback(
    ({target: {value, name}}) => {
      dispatch({type: actionTypes.SET_ORDER_VALUE, payload: {name, value}})
    },
    [dispatch]
  )

  const updateTaxfree = useCallback(
    checked =>
      dispatch({
        type: actionTypes.SET_ORDER_VALUE,
        payload: {name: 'taxfree', value: checked},
      }),
    [dispatch]
  )

  const updateDelivery = useCallback(
    value => {
      dispatch({
        type: actionTypes.SET_ORDER_VALUE,
        payload: {name: 'deliveryType', value},
      })
    },
    [dispatch]
  )

  const updateDate = useCallback(
    date =>
      dispatch({
        type: actionTypes.SET_ORDER_VALUE,
        payload: {name: 'pickupDate', value: date},
      }),
    [dispatch]
  )

  const onAddProduct = id => {
    dispatch({type: actionTypes.ADD_ORDER_ITEM, payload: id})
  }

  const onSetProduct = (id, amount) => {
    dispatch({type: actionTypes.SET_ORDER_ITEM, payload: {id, amount}})
  }

  const onRemoveProduct = id => {
    dispatch({type: actionTypes.REMOVE_ORDER_ITEM, payload: id})
  }

  const updateValueAutocompleted = useCallback(
    (event, {newValue}) => {
      dispatch({
        type: actionTypes.SET_ORDER_VALUE,
        payload: {name: 'name', value: newValue},
      })
    },
    [dispatch]
  )

  const getSuggestions = async value => {
    const q = value.trim().toLowerCase()

    if (q.length < 1) {
      return []
    }

    const matches = await getJSON(`/customer/search?q=${q}`)
    return setSuggestions(matches)
  }

  const onSuggestionsFetchRequested = ({value}) => {
    getSuggestions(value)
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const onSuggestionSelected = (event, {suggestion}) => {
    Object.keys(suggestion).forEach(key => {
      const value = suggestion[key]
      const name = key === 'id' ? 'customerId' : key

      dispatch({
        type: actionTypes.SET_ORDER_VALUE,
        payload: {name, value},
      })
    })
  }

  const getSuggestionValue = suggestion => suggestion.name

  const renderSuggestion = suggestion => (
    <div>
      {suggestion.name}
      <br />
      <span>{suggestion.address}</span>
    </div>
  )

  const searchRe = new RegExp(filterText, 'i')

  const tmp = map(inventory, (p, id) => ({
    id,
    ...p,
  }))

  const inventoryList = map(
    filter(
      tmp,
      p => p.bottles !== 0 && (searchRe.test(p.id) || searchRe.test(p.name))
    ),
    p => {
      const {id} = p
      const onClick = () => onAddProduct(p.id)

      const rtls = p.bottles / p.retailSize

      const orderRtls = has(order.products, id) ? order.products[id] : 0
      const rtlsAvailable = rtls - orderRtls

      return (
        <li key={id}>
          <div className="product-id">{id}</div>
          <div className="product-name">{p.name}</div>
          <div className="product-warehouse">
            {p.warehouse === 'inta' ? 'I' : 'B'}
          </div>
          <div className="product-retailSize">
            {numRetailsAvailable(
              p.retailSize,
              p.bottles - orderRtls * p.retailSize
            )}
          </div>
          <div className="product-bottles">
            {p.bottles - orderRtls * p.retailSize}
          </div>
          <div className="product-add">
            <Button isActive={rtlsAvailable >= 1} onClick={onClick} />
          </div>
        </li>
      )
    }
  )

  return (
    <>
      <h2>Create new order</h2>
      <div id="order">
        <h3>Receiver</h3>
        <div className="form">
          <input
            autoComplete="false"
            name="hidden"
            type="text"
            style={{display: 'none'}}
          />
          <div className="row">
            <div className="hdr">name</div>
            <div className="val">
              <Autosuggest
                suggestions={suggestions}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                inputProps={{
                  value: order.name,
                  placeholder: 'Customer name',
                  onChange: updateValueAutocompleted,
                  className: 'val',
                  name: 'name',
                  type: 'search',
                  autoComplete: 'disable-please',
                }}
              />
            </div>
          </div>
          {/* <TextRow
            title="name"
            name="name"
            value={order.name}
            onChange={updateValue}
          /> */}
          <TextRow
            title="address"
            name="address"
            value={order.address}
            onChange={updateValue}
          />
          <TextRow
            title="zip"
            name="zip"
            value={order.zip}
            onChange={updateValue}
          />
          <TextRow
            title="city"
            name="city"
            value={order.city}
            onChange={updateValue}
          />
          <TextRow
            title="country"
            name="country"
            value={order.country}
            onChange={updateValue}
          />
          <TextRow
            title="email"
            name="email"
            value={order.email}
            onChange={updateValue}
          />
          <TextRow
            title="license"
            name="licenseNumber"
            value={order.licenseNumber}
            onChange={updateValue}
            placeholder="Alcohol license number"
          />
          <TextRow
            title="extra"
            name="addressExtra"
            value={order.addressExtra}
            onChange={updateValue}
            placeholder="Phone number, door code, etc"
          />
          <h3>Reference</h3>
          <TextRow
            title="sender"
            name="senderReference"
            value={order.senderReference}
            onChange={updateValue}
            placeholder="Optional sender reference #"
          />
          <TextRow
            title="receiver"
            name="receiverReference"
            value={order.receiverReference}
            onChange={updateValue}
            placeholder="Optional receiver reference #"
          />
          <CheckBoxRow
            title="tax free"
            name="taxfree"
            checked={order.taxfree}
            onChange={updateTaxfree}
          />
          <TextRow
            title="warehouse id"
            name="taxfreeWarehouseId"
            value={order.taxfreeWarehouseId}
            onChange={updateValue}
            placeholder="Receiver tax-free warehouse #"
          />

          <h3>Delivery</h3>
          <DateRow
            title="date"
            date={order.pickupDate}
            onChange={updateDate}
            allowGone
          />
          <TextRow
            title="details"
            name="details"
            value={order.details}
            onChange={updateValue}
            placeholder="Pick up and delivery instructions"
          />
          <TextRow
            title="email"
            name="emailTo"
            value={order.emailTo}
            onChange={updateValue}
            placeholder="Send order email"
          />
          <TextRow
            title="remarks"
            name="remarks"
            value={order.remarks}
            onChange={updateValue}
            placeholder="Warehouse remarks"
          />
          <ButtonRow
            title="delivery"
            name="delivery"
            value={order.deliveryType}
            values={['pickup', 'delivery']}
            onChange={updateDelivery}
          />
          <h3>Products</h3>
          <ProductList
            products={order.products}
            inventory={inventory}
            onAddProduct={onAddProduct}
            onRemoveProduct={onRemoveProduct}
            onSetProduct={onSetProduct}
          />
          <button className="button-wide" type="submit" onClick={sendOrder}>
            Send
          </button>
          <h3>Inventory</h3>
          <input
            type="text"
            className="filter"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            placeholder="Filter"
          />

          <ul className="product-list">
            <li className="header">
              <div className="product-id">Product id</div>
              <div className="product-name">Name</div>
              <div className="product-warehouse">wh</div>
              <div className="product-retailSize">rtls</div>
              <div className="product-bottles">btls</div>
              <div className="product-add">&nbsp;</div>
            </li>
            {inventoryList}
          </ul>
        </div>
      </div>
    </>
  )
})
