const initialState = {
  customerId: -1,
  name: '',
  address: '',
  zip: '',
  city: '',
  country: 'Suomi',
  addressExtra: '',
  email: '',
  senderReference: '',
  receiverReference: '',
  licenseNumber: '',
  details: '',
  taxfree: false,
  taxfreeWarehouseId: '',
  remarks: '',
  deliveryType: 'pickup',
  emailTo: 'orders@inta.fi, info@kuljetuseaalto.fi',
  pickupDate: new Date(),
  products: {},
}

export default initialState
