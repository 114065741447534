export const getBaseURL = () =>
  process.env.NODE_ENV === 'production' ? '/api' : 'http://127.0.0.1:9000'

const getAuthToken = () => localStorage.getItem('token')

const get = async path =>
  fetch(`${getBaseURL()}${path}`, {
    headers: {
      Authorization: `bearer ${getAuthToken()}`,
    },
  })

export const getPDF = async path => {
  const response = await get(path)
  return response.blob()
}

export const getJSON = async path => {
  const response = await get(path)

  let responseText = null
  let responseJSON = null
  let parsedResponse = null
  let responseIsJSON = true

  // eslint-disable-next-line fp/no-mutation
  responseText = await response.text()

  try {
    // eslint-disable-next-line fp/no-mutation
    responseJSON = JSON.parse(responseText)
    // eslint-disable-next-line fp/no-mutation
    parsedResponse = responseJSON
  } catch (err) {
    // eslint-disable-next-line fp/no-mutation
    responseIsJSON = false
    // eslint-disable-next-line fp/no-mutation
    parsedResponse = responseText
  }

  if (response.status !== 200) {
    throw new Error(responseIsJSON ? parsedResponse.error : parsedResponse)
  }

  return parsedResponse
}

export const postJSON = async (path, data) => {
  let json = null
  let response = null

  try {
    // eslint-disable-next-line fp/no-mutation
    response = await fetch(`${getBaseURL()}${path}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `bearer ${getAuthToken()}`,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    })

    // eslint-disable-next-line fp/no-mutation
    json = await response.json()
  } catch (err) {
    return {err}
  }

  if (response.status !== 200) {
    throw new Error(json.error)
  }
  return json
}

export const getDate = date => {
  const y = date.getFullYear()
  const m = date.getMonth() + 1
  const d = date.getDate()

  return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`
}

export const numRetailsAvailable = (retailSize, bottles) =>
  bottles % retailSize === 0
    ? bottles / retailSize
    : (bottles / retailSize).toFixed(1).replace('.', ',')
