import initialState from './initialState'
import {ADD_PRODUCT, SET_PRODUCT_VALUE, RELOAD_INVENTORY} from './actionTypes'

export default (state = initialState, action) => {
  if (action.type === RELOAD_INVENTORY) {
    return action.payload
  }

  if (action.type === SET_PRODUCT_VALUE) {
    const productId = action.payload.id

    return {
      ...state,
      [productId]: {
        ...state[productId],
        [action.payload.name]: action.payload.value,
      },
    }
  }

  if (action.type === ADD_PRODUCT) {
    return {
      ...state,
      [action.payload.id]: {
        ...action.payload.product,
      },
    }
  }

  return state
}
