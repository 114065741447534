import React from 'react'
import {Switch, Route, useRouteMatch} from 'react-router-dom'
import {useSelector} from 'react-redux'
// import {Icon} from 'react-icons-kit'
// import {iosPlusOutline} from 'react-icons-kit/ionicons/iosPlusOutline'

import {selectors} from '../../features/inventory'

import ProductList from '../ProductList'
import EditProduct from '../EditProduct'

export default function Inventory() {
  const inventory = useSelector(selectors.getInventory)
  const match = useRouteMatch()

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:productId`}>
          <EditProduct />
        </Route>
        <Route path={match.path}>
          <h2>Products</h2>
          <ProductList products={inventory} />
        </Route>
      </Switch>
    </div>
  )
}
