import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Icon} from 'react-icons-kit'
import {iosPaperOutline} from 'react-icons-kit/ionicons/iosPaperOutline'
import {getBaseURL, getJSON} from 'utils'

const ArrivalHistory = () => {
  const [arrivals, setArrivals] = useState([])

  useEffect(() => {
    const fetchArrivals = async () => {
      const res = await getJSON('/arrival/list')
      setArrivals(res)
    }

    fetchArrivals()
  }, [])

  const arrivalList = arrivals.map(arrival => {
    const d = new Date(arrival.date)
    const products = arrival.products.map(p => (
      <li key={p.id} className="arrival">
        <div className="id">
          <Link to={`/inventory/${p.id}`}>{p.id}</Link>
        </div>
        <div className="name">{p.name}</div>
        <div className="count">1</div>
        <div className="count">{p.bottles}</div>
      </li>
    ))

    return (
      <div key={arrival.id} className="entry">
        <div className="header">
          <div>{arrival.title}</div>
          <div className="date">
            {d.getDate()}.{d.getMonth() + 1}.{d.getFullYear()}
          </div>
        </div>

        <div className="details">
          <div>
            <span>ARC {arrival.arc}</span>
            <br />
            {arrival.notes}
          </div>
          <div className="right">
            <a href={`${getBaseURL()}/arrival/history/${arrival.id}.pdf`}>
              <Icon size={38} icon={iosPaperOutline} />
            </a>
          </div>
        </div>

        <ul>
          <li className="hdr" key="hdr">
            <div className="id">Product id</div>
            <div className="name">Name</div>
            <div className="count">Rtls</div>
            <div className="count">Btls</div>
          </li>
          {products}
        </ul>
      </div>
    )
  })

  return (
    <>
      <h2>Arrivals</h2>
      <div className="history">{arrivalList}</div>
    </>
  )
}

export default ArrivalHistory
