import React from 'react'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {wrapComponent} from 'react-snackbar-alert'

import {postJSON} from 'utils'
import ProductEditor from '../ProductEditor'
import {selectors, actionTypes} from '../../features/inventory'

const newProduct = {
  id: '',
  name: '',
  producer: '',
  ean: '',
  alc: 0,
  bottleSize: 0.75,
  bottles: 0,
  retailSize: '',
  // tuoteryhmä (viini, viina, olut, välituote)
  category: 'wine',
  // hyllypaikka
  storageArea: '',
  storageShelf: '',
  // hinta
  priceIn: 0,
  priceOut: 0,
  alkoApproved: false,
  eanRequired: true,
  taxFree: false,
  taxExempt: false,
}

const categories = {
  wine: 'viini',
  wine15plus: 'viini yli 15%',
  beer: 'olut',
  intermediate: 'välituote',
  liquor: 'viina',
  lowalc: 'low alc.',
  softdrink: 'virvoitusjuoma',
  nonalcoholic: 'alkoholiton',
}

export default wrapComponent(function EditProduct({createSnackbar, readonly}) {
  const {productId} = useParams()
  const product = useSelector(selectors.getProduct(productId))
  const dispatch = useDispatch()

  const saveProduct = async () => {
    const status = await postJSON(`/product/${productId}/update`, product)

    createSnackbar(
      status.err
        ? {
            message: status.err,
            theme: 'error',
            sticky: true,
            dismissable: true,
          }
        : {
            message: 'Product updated',
            progressBar: true,
            theme: 'success',
            timeOut: 1500,
          }
    )
  }

  const updateProduct = (name, value) => {
    dispatch({
      type: actionTypes.SET_PRODUCT_VALUE,
      payload: {id: productId, name, value},
    })
  }

  if (productId && !product) {
    return <div>loading....</div>
  }

  const editProduct = productId ? {id: productId, ...product} : newProduct

  return (
    <div>
      <h2>Edit {productId}</h2>
      <ProductEditor
        editId={false}
        product={editProduct}
        categories={categories}
        onUpdate={updateProduct}
        onSave={saveProduct}
      />
    </div>
  )
})

// EditProduct.propTypes = {
//   readonly: PropTypes.bool,
// }

// EditProduct.defaultProps = {
//   readonly: true,
// }
