import React, {useState} from 'react'
import PropTypes, {string} from 'prop-types'
import {Icon} from 'react-icons-kit'
import {alertCircled} from 'react-icons-kit/ionicons/alertCircled'
import {map, filter, orderBy} from 'lodash'
// import {FaBoxOpen} from 'react-icons/fa'

import {numRetailsAvailable} from 'utils'
import {Link} from 'react-router-dom'

export default function ProductList({products, showAll}) {
  const [sortKey, setSortKey] = useState('id')
  const [sortDir, setSortDir] = useState('asc')

  const updateSortKey = key => {
    if (key === sortKey) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(key)
    }
  }

  const tmp = map(products, (p, id) => {
    let sortValue
    if (sortKey === 'id') {
      // eslint-disable-next-line fp/no-mutation
      sortValue = id.toUpperCase()
    } else if (sortKey === 'btls') {
      // eslint-disable-next-line fp/no-mutation
      sortValue = p.bottles
    } else if (sortKey === 'rtls') {
      // eslint-disable-next-line fp/no-mutation
      sortValue = p.bottles / p.retailSize
    } else {
      // eslint-disable-next-line fp/no-mutation
      sortValue = p[sortKey]
    }

    return {
      id,
      ...p,
      sortValue,
    }
  })

  const productList = map(
    filter(orderBy(tmp, 'sortValue', sortDir), p => !p.legacy),
    p =>
      showAll || p.bottles ? (
        <li key={p.id}>
          <div className="product-id">
            <Link to={`/inventory/${p.id}`}>{p.id}</Link>
          </div>
          <div className="product-name">{p.name}</div>
          <div className="product-alert">
            {p.fieldsMissing ? <Icon size={18} icon={alertCircled} /> : ' '}
          </div>
          <div className="product-warehouse">
            {p.warehouse === 'inta' ? 'I' : 'B'}
          </div>
          <div className="product-retailSize">
            {numRetailsAvailable(p.retailSize, p.bottles)}
          </div>
          <div className="product-bottles">{p.bottles}</div>
          {/* <div className="product-split">
          <FaBoxOpen size="1.1rem" />
        </div> */}
        </li>
      ) : null
  )

  const HdrCol = ({id, title}) => (
    <div
      className={`product-${id} ${sortKey === id && 'sortKey'}`}
      onClick={() => updateSortKey(id)}
      onKeyPress={() => updateSortKey(id)}
      role="button"
      tabIndex="0">
      {title}
    </div>
  )

  HdrCol.propTypes = {
    id: string.isRequired,
    title: string.isRequired,
  }

  return (
    <>
      <ul className="product-list">
        <li className="header">
          <HdrCol id="id" title="Product" />
          <HdrCol id="name" title="Name" />
          <div className="product-alert" />
          <HdrCol id="warehouse" title="wh" />
          <HdrCol id="rtls" title="rtls" />
          <HdrCol id="bottles" title="btls" />
          {/* <HdrCol id="split" title="split" /> */}
        </li>
        {productList}
      </ul>

      {/* <p className="product-total">
        total bottles: <em>{totalBottles}</em>
      </p> */}
    </>
  )
}

ProductList.defaultProps = {
  showAll: false,
}

ProductList.propTypes = {
  products: PropTypes.shape([]).isRequired,
  showAll: PropTypes.bool,
}
