import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {getJSON} from 'utils'

const getTimestamp = date => {
  const h = date.getHours()
  const m = date.getMinutes()

  return `${date.getDate()}.${date.getMonth() + 1}. ${date.getFullYear()} ${
    h < 10 ? `0${h}` : h
  }:${m < 10 ? `0${m}` : m}`
}

const InventoryHistory = () => {
  const [inventoryReports, setInventoryReports] = useState([])

  useEffect(() => {
    const fetchInventoryReports = async () => {
      const res = await getJSON('/inventory/report/list')
      setInventoryReports(res)
    }

    fetchInventoryReports()
  }, [])

  const inventoryList = inventoryReports.map(inventory => {
    const link =
      inventory.status === 'DRAFT'
        ? '/admin/inventory/report'
        : '/stock/inventory'
    const title = inventory.status === 'DRAFT' ? 'Draft' : 'Report'

    return (
      <div key={inventory.id}>
        <div className="header">
          <div>
            <Link to={`${link}/${inventory.id}`}>
              {title} - {inventory.warehouse.toUpperCase()}
            </Link>
          </div>
          <div className="date">
            {getTimestamp(new Date(inventory.created))}
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <h2>Inventory reports</h2>
      <div className="history">{inventoryList}</div>
    </>
  )
}

export default InventoryHistory
