import React from 'react'
import {useSelector} from 'react-redux'
import {selectors} from '../../features/error'

const Error = () => {
  const error = useSelector(selectors.getError)

  if (!error) {
    return null
  }

  return (
    <div id="error">
      <p>Error: {error}</p>
      <p>
        <a href="/login">Log in to continue</a>
      </p>
    </div>
  )
}

export default Error
