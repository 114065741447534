import React, {useCallback, useReducer, useState} from 'react'
import Autosuggest from 'react-autosuggest'
import {has, reduce} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {wrapComponent} from 'react-snackbar-alert'
import {actionCreators, selectors} from 'features/inventory'
import Button from 'components/Button'
import DateRow from 'components/DateRow'
import TextRow from 'components/TextRow'
import {postJSON} from 'utils'

const newArrival = {
  arc: '',
  title: '',
  notes: '',
  date: new Date(),
  products: [],
  addProductId: '',
}

const RESET_ARRIVAL = 'RESET_ARRIVAL'
const ADD_PRODUCT = 'ADD_PRODUCT'
const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
const UPDATE_PRODUCT_BOTTLES = 'UPDATE_PRODUCT_BOTTLES'

const reducer = (state, action) => {
  if (action.type === RESET_ARRIVAL) {
    return newArrival
  }

  if (action.type === ADD_PRODUCT) {
    return {
      ...state,
      products: [...state.products, {id: action.payload, bottles: 0}],
    }
  }

  if (action.type === REMOVE_PRODUCT) {
    return {
      ...state,
      products: state.products.filter(p => p.id !== action.payload),
    }
  }

  if (action.type === UPDATE_PRODUCT_BOTTLES) {
    const {id, bottles} = action.payload

    return {
      ...state,
      products: state.products.map(p => ({
        ...p,
        bottles: p.id === id ? bottles : p.bottles,
      })),
    }
  }

  return {...state, [action.type]: action.payload}
}

export default wrapComponent(function Arrival({createSnackbar}) {
  const dispatch = useDispatch()
  const [state, localstateDispatch] = useReducer(reducer, newArrival)
  const inventory = useSelector(selectors.getInventory)
  const [suggestions, setSuggestions] = useState([])

  const updateValue = useCallback(({target: {value, name, type}}) => {
    localstateDispatch({type: name, payload: value})
  }, [])

  const updateValueAutocompleted = useCallback((event, {newValue}) => {
    localstateDispatch({type: 'addProductId', payload: newValue})
  }, [])

  const updateDate = useCallback(date => {
    localstateDispatch({type: 'date', payload: date})
  }, [])

  const updateProductBottles = useCallback(({target: {value, name}}) => {
    localstateDispatch({
      type: UPDATE_PRODUCT_BOTTLES,
      payload: {id: name, bottles: value},
    })
  }, [])

  const sendArrival = async () => {
    const res = await postJSON('/arrival/create', state)

    if (!res.err) {
      dispatch(actionCreators.reloadInventory)
      localstateDispatch({type: RESET_ARRIVAL})
    }

    createSnackbar(
      res.err
        ? {
            message: res.err,
            theme: 'error',
            sticky: true,
            dismissable: true,
          }
        : {
            message: 'Arrival created',
            progressBar: true,
            theme: 'success',
            timeOut: 1500,
          }
    )
  }

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()

    const productMatches = (id, name) =>
      id.toLowerCase().indexOf(inputValue) > -1 ||
      name.toLowerCase().indexOf(inputValue) > -1

    const productInOrder = id => state.products.findIndex(p => p.id === id) > -1

    return inputValue.length < 1
      ? []
      : reduce(
          inventory,
          (out, p, id) => {
            if (productMatches(id, p.name) && !productInOrder(id)) {
              return [
                ...out,
                {
                  id,
                  name: p.name,
                },
              ]
            }

            return out
          },
          []
        )
  }

  const onSuggestionsFetchRequested = ({value}) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const onSuggestionSelected = (event, {suggestion}) => {
    localstateDispatch({type: ADD_PRODUCT, payload: suggestion.id})
    localstateDispatch({type: 'addProductId', payload: ''})
  }

  const getSuggestionValue = suggestion => suggestion.id

  const renderSuggestion = suggestion => (
    <div>
      {suggestion.id}
      <br />
      <span>{suggestion.name}</span>
    </div>
  )

  const products = state.products.map(p => {
    const {id} = p

    if (!has(inventory, id)) {
      return null
    }

    return (
      <li key={id}>
        <div className="product-id">{id}</div>
        <div className="product-name">{inventory[id].name}</div>
        <div className="product-bottles">
          <input
            type="text"
            name={id}
            size={4}
            value={p.bottles}
            onChange={updateProductBottles}
          />
        </div>
        <div className="product-add">
          <Button
            mode="remove"
            onClick={() =>
              localstateDispatch({type: REMOVE_PRODUCT, payload: id})
            }
          />
        </div>
      </li>
    )
  })

  return (
    <>
      <h2>New arrival</h2>

      <div className="form">
        <TextRow
          title="title"
          name="title"
          placeholder="Arrival title"
          value={state.title}
          onChange={updateValue}
        />

        <DateRow
          title="date"
          date={state.date}
          onChange={updateDate}
          allowGone
        />
        <TextRow
          title="ARC"
          name="arc"
          placeholder="ARC number"
          value={state.arc}
          onChange={updateValue}
        />

        <TextRow
          title="notes"
          name="notes"
          placeholder="Arrival notes"
          value={state.notes}
          onChange={updateValue}
        />

        <h3>Products</h3>
        <div className="row">
          <div className="hdr">add</div>
          <div className="val">
            <Autosuggest
              suggestions={suggestions}
              renderSuggestion={renderSuggestion}
              onSuggestionSelected={onSuggestionSelected}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              inputProps={{
                value: state.addProductId,
                placeholder: 'Product ID',
                onChange: updateValueAutocompleted,
                className: 'val',
                name: 'id',
              }}
            />
          </div>
        </div>

        <ul className="product-list noborder">{products}</ul>

        <button className="button-wide" type="submit" onClick={sendArrival}>
          Save
        </button>
      </div>
    </>
  )
})
