import {has, reduce} from 'lodash'

import {
  ADD_ORDER_ITEM,
  SET_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  SET_ORDER_VALUE,
  RESET_ORDER,
} from './actionTypes'
import initialState from './initialState'

export default (state = initialState, action) => {
  if (action.type === RESET_ORDER) {
    return initialState
  }

  if (action.type === ADD_ORDER_ITEM) {
    const productId = action.payload

    const newAmount = has(state.products, productId)
      ? state.products[productId] + 1
      : 1

    return {
      ...state,
      products: {
        ...state.products,
        [productId]: newAmount,
      },
    }
  }

  if (action.type === SET_ORDER_ITEM) {
    return {
      ...state,
      products: {
        ...state.products,
        [action.payload.id]: action.payload.amount,
      },
    }
  }

  if (action.type === REMOVE_ORDER_ITEM) {
    const productId = action.payload

    return {
      ...state,
      products: reduce(
        state.products,
        (out, count, id) => {
          if (id === productId) {
            if (count > 1) {
              return {...out, [id]: count - 1}
            }

            return out
          }

          return {...out, [id]: count}
        },
        {}
      ),
    }
  }

  if (action.type === SET_ORDER_VALUE) {
    return {
      ...state,
      [action.payload.name]: action.payload.value,
    }
  }

  return state
}
