import React from 'react'
import {Switch, Route, useRouteMatch, Link} from 'react-router-dom'

import Calculator from 'components/Calculator'
import StockStatus from 'components/StockStatus'
import CreateProduct from 'components/CreateProduct'
import InventoryReport from 'components/InventoryReport'
import SalesReport from 'components/SalesReport'
import AllProducts from 'components/AllProducts'
import InventoryHistory from './InventoryHistory'

export default function Admin() {
  const match = useRouteMatch()

  return (
    <>
      <Switch>
        <Route path={`${match.path}/product/new`}>
          <CreateProduct />
        </Route>
        <Route path={`${match.path}/products`}>
          <AllProducts />
        </Route>
        <Route path={`${match.path}/inventory/report/:inventoryId`}>
          <InventoryReport />
        </Route>
        <Route path={`${match.path}/inventory/create-report/:warehouseId`}>
          <InventoryReport />
        </Route>
        <Route path={`${match.path}/inventory/reports`}>
          <InventoryHistory />
        </Route>
        <Route path={`${match.path}/inventory/stock`}>
          <StockStatus />
        </Route>
        <Route path={`${match.path}/sales/report`}>
          <SalesReport />
        </Route>
        <Route path={`${match.path}/calculator`}>
          <Calculator />
        </Route>

        <Route path={match.path}>
          <h2>Admin</h2>

          <p className="button">
            <Link to={`${match.path}/product/new`}>New product</Link>
          </p>
          <p className="button">
            <Link to={`${match.path}/products`}>All products</Link>
          </p>
          <p className="button">
            <Link to={`${match.path}/inventory/create-report/bewe`}>
              Inventory report - BEWE
            </Link>
          </p>
          <p className="button">
            <Link to={`${match.path}/inventory/create-report/inta`}>
              Inventory report - INTA
            </Link>
          </p>
          <p className="button">
            <Link to={`${match.path}/inventory/reports`}>
              Inventory reports
            </Link>
          </p>
          <p className="button">
            <Link to={`${match.path}/inventory/stock`}>Stock status</Link>
          </p>
          <p className="button">
            <Link to={`${match.path}/sales/report`}>Reports</Link>
          </p>
          <p className="button">
            <Link to={`${match.path}/calculator`}>Calculator</Link>
          </p>
        </Route>
      </Switch>
    </>
  )
}
