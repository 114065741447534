export default `
table, thead, tbody, tr, th, td {
  padding: 0;
  margin: 0; 
  border: 0; 
}

table {
  border-collapse: collapse;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 2em;
  text-align: left;
}

thead tr:nth-child(2) th,
tbody tr:nth-child(1) th {
  border-bottom: 1px solid black;
}

thead th {
  font-weight: bold;
  padding: 2em 0 0.25em 0;
}

thead td {
  padding-top: 0.25rem;
}

tbody td {
  padding-top: 0.5rem;
}
`
