import React from 'react'
import PropTypes from 'prop-types'

const TextRow = ({title, name, value, size, type, onChange, placeholder}) => (
  <div className="row">
    <div className="hdr">{title}</div>
    <div className="val">
      <input
        type={type}
        name={name}
        size={size}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="please-chrome-dont-do-it"
      />
    </div>
  </div>
)

TextRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
}

TextRow.defaultProps = {
  size: 20,
  type: 'text',
  placeholder: '',
}

export default TextRow
