import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {isEmpty} from 'lodash'

import {getJSON} from 'utils'

const getTimestamp = date => {
  const h = date.getHours()
  const m = date.getMinutes()

  return `${date.getDate()}.${date.getMonth() + 1}. ${date.getFullYear()} ${
    h < 10 ? `0${h}` : h
  }:${m < 10 ? `0${m}` : m}`
}

const createInventoryList = products =>
  products.map(product => {
    const diff = product.newBottles - product.oldBottles

    return (
      <li key={product.product_id}>
        <div className="product-id">{product.product_id}</div>
        <div className="product-name">{product.comment}</div>
        <div className="product-bottles">{product.oldBottles}</div>
        <div className="product-bottles">{product.newBottles}</div>
        <div className="product-add">{diff > 0 ? `+${diff}` : diff}</div>
      </li>
    )
  })

const InventoryDetails = () => {
  const {inventoryId} = useParams()
  const [inventory, setInventory] = useState([])

  useEffect(() => {
    const fetchInventoryDetails = async () => {
      const res = await getJSON(`/inventory/report/details/${inventoryId}`)
      setInventory(res)
    }

    fetchInventoryDetails()
  }, [inventoryId])

  if (isEmpty(inventory)) {
    return null
  }

  return (
    <>
      <h2>
        {inventory.status === 'DRAFT' ? 'Draft' : 'Inventory'} report{' '}
        {getTimestamp(new Date(inventory.created))}
      </h2>
      <ul className="product-list noborder">
        <li className="header">
          <div className="product-id">Product id</div>
          <div className="product-name">Comment</div>
          <div className="product-bottles">Previous</div>
          <div className="product-bottles">New</div>
          <div className="product-add">Change</div>
        </li>
        {createInventoryList(inventory.products)}
      </ul>
    </>
  )
}

export default InventoryDetails
