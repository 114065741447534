import React from 'react'
import {Switch, Route, useRouteMatch} from 'react-router-dom'
import StockReport from './StockReport'

export default function Stock() {
  const match = useRouteMatch()

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/inventory/:inventoryId`}>
          <StockReport />
        </Route>
      </Switch>
    </div>
  )
}
