import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {wrapComponent} from 'react-snackbar-alert'
import {postJSON} from 'utils'
import {actionCreators} from 'features/inventory'

import TextRow from '../TextRow'

const Login = wrapComponent(({createSnackbar}) => {
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()

  const updateValue = e => {
    if (e.target.name === 'user') {
      setUser(e.target.value)
    } else {
      setPass(e.target.value)
    }
  }

  const logIn = async () => {
    const response = await postJSON(`/auth`, {user, pass})

    if (response.validUser) {
      localStorage.setItem('token', response.token)
      dispatch(actionCreators.reloadInventory)
      // eslint-disable-next-line fp/no-mutating-methods
      history.push('/inventory')
    } else {
      createSnackbar({
        message: 'Login failed',
        theme: 'error',
        progressBar: true,
        dismissable: true,
        timeout: 1000,
      })
    }
  }

  return (
    <div id="login">
      <h1>log in</h1>
      <div className="form">
        <div className="row">
          <TextRow
            title="user"
            name="user"
            value={user}
            onChange={updateValue}
          />
        </div>

        <div className="row">
          <TextRow
            title="pass"
            name="pass"
            value={pass}
            type="password"
            onChange={updateValue}
          />
        </div>
      </div>

      <button className="button-wide" type="submit" onClick={logIn}>
        Submit
      </button>
    </div>
  )
})

export default Login
