import {combineReducers, applyMiddleware, compose, createStore} from 'redux'
import thunk from 'redux-thunk'

import {InventoryReducer, actionCreators} from './features/inventory'
import {OrderReducer} from './features/order'
import {ErrorReducer} from './features/error'

const reducer = combineReducers({
  error: ErrorReducer,
  order: OrderReducer,
  inventory: InventoryReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

store.dispatch(actionCreators.reloadInventory)

export default store
